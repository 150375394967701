import { WorkspaceSelector } from '@/components/CardAuthorBox/CardAuthorBox';
import StartRating from '@/components/StartRating/StartRating';
import Avatar from '@/shared/Avatar/Avatar';
import ButtonSecondary from '@/shared/Button/ButtonSecondary';
import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';

export default function PublisherSection({
  workspace
}:{
    workspace: WorkspaceSelector
}) {

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">About adboard owner</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
    
        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <Link className="block text-xl font-medium" to={'/workspace'+workspace.key}>
              {workspace.name}
            </Link>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> {workspace.adBoards.length} listings</span>
            </div>
          </div>
        </div>
    
        {/* desc */}
        {/* <span className="block text-neutral-6000 dark:text-neutral-300">
              Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
              accommodation, an outdoor swimming pool, a bar, a shared lounge, a
              garden and barbecue facilities...
        </span> */}
    
        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in {moment(workspace.createdAt as string).format('MMM-YYYY')}</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
    
            <span>Fast response - within a few hours</span>
          </div>
        </div>
    
        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <Link to={'/workspace'+workspace.key}>
            <ButtonSecondary>See host profile</ButtonSecondary>
          </Link>
        </div>
      </div>
    );
  };
      
  return (
    <div>
      {renderSection5()}
    </div>
  )
}
